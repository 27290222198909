<template>
  <!-- <div class="per p20 bsbb">
        <div style="margin-bottom: 20px;">
            <van-tabs v-model:active="active" swipeable>
                <van-tab v-for="index in classify" :title="index.classification_name"
                    title-active-color="rgba(55, 159, 0, 1)">
                    <div class="divbox">
                        <van-row gutter="20">
                            <van-col span="11" class="shopbox" v-for="(item, i) in index.children" :key="i"
                                @click="product(item)">
                                <img :src="item.shop_home_img" alt="" style="width: 100%;">
                                <p
                                    style="margin-top: 10px;font-size: 16px;font-weight: bold; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical;  -webkit-line-clamp: 1;">
                                    {{ item.trade_name }}</p>
                                <p style="margin-top: 10px;font-size: 14px;">香甜可口 饱满丰盈</p>
                                <p style="margin-top: 10px;position: relative;margin-bottom: 10px;font-size: 14px;"> <span
                                        style="color: red;">￥{{ item.trade_price }}</span> <span
                                        style="font-size: 12px;">22.9</span> <span
                                        style="position: absolute;right: 0px;font-size: 12px;">月销1826件</span></p>
                            </van-col>
                        </van-row>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div> -->
  <van-nav-bar style="padding: 15px;" left-arrow @click-left="onClickLeft">
    <template #title>
      <div class="top-nav df aic jcsb">
        <div class="df aic jcc">
          <span style="font-size: 18px; color: #000000; line-height: normal">鏈上優選</span>
        </div>
      </div>
    </template>
  </van-nav-bar>
  <div style="padding: 0 15px 15px 15px;">
    <img src="../../assets/images/shopbanner.png" style="width: 100%;border-radius: 10px;" alt="">
  </div>
  <div class="per df aic jcsb bsbb">
    <div class="classify">
      <div class="bsbb"
        style="font-size: 16px;height: 50px;padding: 5px;display: flex;align-items: center;justify-content: center;"
        v-for="(v, i) in className" :key="i" :class="i == 0 ? 'active' : ''">{{ v }}</div>
    </div>
    <div class="content p20 bsbb">
      <div v-for="(v, i) in className.length" :key="i" :class="i == 0 ? 'on' : ''" class="das">
        <div class="item" v-for="(subitem, subindex) in classify[i].children" :key="subindex" @click="product(subitem)"
          style="margin-top: 10px;">
          <img :src="subitem.shop_home_img" style="width: 100%;border-radius: 20px;" class="mr20">
          <div class="fz30 df fdc p10 bsbb" style="width:100%">
            <p class="mb40 text-container" style="color: #2A2A2A;">{{ subitem.trade_name }}</p>
            <div class="df aic jcsb" style="position:relative;">
              <p style="color: #2A2A2A">{{ subitem.trade_price }}</p>
              <van-icon size="24" name="cart-circle-o"  color="#3E74EE" style="position:absolute;right:0;top:0" />
            </div>
          </div>
        </div>
        <!-- <div class="list df aic jcsb">
            </div> -->
        <!-- <van-row gutter="20">
              <van-col span="11" class="shopbox" v-for="(item, i) in classify.children" :key="i"
              @click="product(item)">
              <img :src="item.shop_home_img" alt="" style="width: 100%;">
              <p
              style="margin-top: 10px;font-size: 16px;font-weight: bold; overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical;  -webkit-line-clamp: 1;">
              {{ item.trade_name }}</p>
              <p style="margin-top: 10px;font-size: 14px;">香甜可口 饱满丰盈</p>
              <p style="margin-top: 10px;position: relative;margin-bottom: 10px;font-size: 14px;"> <span
                style="color: red;">￥{{ item.trade_price }}</span> <span
                style="font-size: 12px;">22.9</span> <span
                style="position: absolute;right: 0px;font-size: 12px;">月销1826件</span></p>
              </van-col>
            </van-row> -->
      </div>
    </div>
  </div>
</template>

<script>
import { CommodityManagementCClass } from '@/utils/api.js'
export default {
  data() {
    return {
      className: [],
      classify: []
    }
  },
  methods: {
    product(data) {
      this.$router.push({
        name: "goodDetail",
        query: {
          id: data.id,
          buy_actual_amount: data.trade_price,
          trade_name: data.trade_name,
          shop_home_img: data.shop_home_img,
          buy_quantity: 1,
          judge: "/home",
        },
      });
    },
    onClickLeft() { history.back() }
  },
  async mounted() {
    await CommodityManagementCClass({}).then((res) => {
      res.data.forEach(v => {
        this.className.push(v.classification_name)
      })
      if (res.code == 200) {
        this.classify = res.data
      }
    })
    const classifies = document.querySelectorAll('.classify > div')
    const contents = document.querySelectorAll('.content > div')
    console.log(classifies, contents)
    classifies.forEach((cl, ind) => {
      cl.addEventListener('click', () => {
        classifies.forEach((v, i) => {
          v.classList.remove('active')
          contents[i].classList.remove('on')
        })
        cl.classList.add("active")
        contents[ind].classList.add('on')
      })
    })
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.van-nav-bar__left) {
  font-size: 32px !important;
  font-weight: bold;
  color: #000;
}
::v-deep .van-nav-bar__arrow {
      font-size: 46px !important;
      padding-top: 10px;
      color: #000;
}
.per {
  background-color: #fff;
  height: 100%;

  .classify {
    height: 100%;
    background-color: #f2f2f2;
    width: 30%;
    overflow-y: scroll;
    border-right: 2px solid #ddd;
    text-align: center;

    >div.active {
      background-color: #fff;
      border-left: 3px solid #3E74EE;
      color: #3E74EE
    }

    >div:hover {
      border-left: 3px solid #3E74EE;
      color: #3E74EE
    }
  }


  .content {
    height: 100%;
    width: 80%;

    >div {
      display: none;

      &.on {
        display: flex;
      }

    }

    .item {
      width: 48%;
      background-color: #fff;
      border-radius: 20px;
    }
    .item:nth-child(2n+1){
      margin-right: 10px;
    }
  }
}
</style>
